"use client";

import React, { useEffect, useState } from "react";
import styles from "./MultiLanguageSelector.module.scss";
import clsx from "clsx";

import { Builder } from "@builder.io/react";
import Markdown from "../UI/Markdown/Markdown";

type Language = {
	name: string;
	content: string;
};

type MultiLanguageSelectorProps = {
	title: string;
	backgroundColor: string;
	languages: Language[];
};

const MultiLanguageSelector: React.FC<MultiLanguageSelectorProps> = ({
	languages,
	title,
	backgroundColor,
}) => {
	const [selected, setSelected] = useState("English");

	useEffect(() => {
		if (languages) {
			setSelected(languages[0].name);
		}
	}, []);

	const selectedLanguage = languages?.find(
		(l) => l.name.toLowerCase() === selected.toLowerCase(),
	);

	const renderLinks = () => {
		return (
			<nav>
				<ul className={styles.navList} role='tablist'>
					{languages
						? languages.map(({ name }) => (
								<li
									key={name}
									className={clsx({
										[styles.active]: name === selected,
									})}
									onClick={() => setSelected(name)}
								>
									{name}
								</li>
						  ))
						: null}
				</ul>
			</nav>
		);
	};

	return (
		<section className={`${styles.contentWrapper}`} style={{ backgroundColor }}>
			<div className={styles.header}>
				{title ? <h2 className={styles.title}>{title}</h2> : null}
				{renderLinks()}
			</div>
			<div className={styles.body}>
				<Markdown
					className={styles.markdown}
					content={selectedLanguage?.content}
				/>
			</div>
		</section>
	);
};

export default MultiLanguageSelector;

Builder.registerComponent(MultiLanguageSelector, {
	name: "Multi Language Selector",
	inputs: [
		{
			name: "title",
			friendlyName: "Title",
			type: "longText",
			required: false,
			defaultValue: "Content Header",
			helperText: "Page Title (optional)",
		},
		{
			name: "backgroundColor",
			friendlyName: "Background Color",
			type: "color",
			defaultValue: "#FFFFFF",
		},
		{
			name: "languages",
			friendlyName: "Languages",
			type: "list",
			// defaultValue: [
			// 	{
			// 		name: "English",
			// 		content: "Language content...",
			// 	},
			// ],
			subFields: [
				{
					name: "name",
					friendlyName: "Language Name",
					type: "string",
					required: true,
					defaultValue: "",
					helperText: "Enter language name.",
				},
				{
					name: "content",
					friendlyName: "Content",
					type: "richText",
					required: true,
					helperText: "Enter the text here.",
				},
			],
		},
	],
});
